<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <b-card-code>
      <div :hidden="hidden">
        <div class="custom-search">
          <!-- advance search input -->
          <b-row>
            <b-form-group class="col-lg-2 col-md-4 ">
              <b-form-input
                placeholder="Rechercher"
                type="text"
                class="d-inline-block"
                v-model="valueSearch"
                @input="filterValue"
              />
            </b-form-group>

            <b-form-group class="col-lg-2 col-md-4 ">
              <b-form-input
                placeholder="Enregistrer du"
                type="date"
                class="d-inline-block"
                @input="startDateFilterFunction"
              />
              <label>Enregistrer du </label>
            </b-form-group>

            <b-form-group class="col-lg-2 col-md-4 ">
              <b-form-input
                @input="endDateFilterFunction"
                placeholder="Au"
                type="date"
                class="d-inline-block"
              />
              <label>Au </label>
            </b-form-group>

            <!-- statut start -->
            <b-form-group class="col-lg-2 col-md-4 ">
              <b-form-select
                v-model="selectedStatut"
                :options="type_factures"
                value-field="text"
                text-field="text"
                @input="selectedTypeFunction"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Association / Importateur --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group class="col-lg-2 col-md-4 ">
              <b-form-select
                v-model="selectedStatusInvoice"
                :options="invoice_types"
                value-field="text"
                text-field="text"
                @input="selectedInvoiceFunction"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Simple / Normalisée --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <!-- statut end -->

            <!-- bouton export start -->
            <b-button class="col-lg-2 col-md-4 mb-3 ml-1"
              variant="outline-primary"
              size="sm"
              @click="ExportFomatExcel"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Exporter
            </b-button>

            <!-- bouton export end -->

            <b-button class="col-lg-2 col-md-4 mb-3 ml-1"
              variant="outline-primary"
              size="sm"
              @click="ExportAllToFomatExcel"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Tout Exporter
            </b-button>

            <div class="col-lg-2 ml-0">
                  <b-form-select
                              ref="status_filter"
                              v-model="selectedTrie"
                              :options="trieDate"
                              @change="changeTrie"
                              @input="filterValue"
                          >
                  </b-form-select>
                  <label>Trier Par Date</label>
            </div>
            <div class="col-lg-2 ml-0">

            <b-button class="mr-2" variant="primary" @click="newfacture()"
            >Nouvelle facture</b-button
            >
            </div>
          </b-row>
        </div>

        <b-table
          v-model="ExcelFactures"
          responsive
          :fields="fields"
          :items="factures"
          :filter="filter"
          hover
          striped
          @row-clicked="onRowClicked"
          :per-page="perPage"
          :current-page="currentPage"
          :filter-function="filterFunc"
          @filtered="onFiltered"
          size="lg"
          :tbody-tr-class="itemRowBackground"
        >
          <template #cell(vehicule)="data">
            <label for="">
              {{ data.item.vehicule.marque }} -
              {{ data.item.vehicule.numero_chassis }} - Année
              {{ data.item.vehicule.annee }}
            </label>
          </template>
          <template #cell(statut)="data">
            <span class="text-uppercase">{{
              data.item.statut ? "archivé" : "non archivé"
            }}</span>
          </template>
          <template #cell(avance_percue)="data">
            {{ $thousandSeparator(data.item.avance_percue) }}
          </template>
          <template #cell(montant_paye)="data">
            {{ $thousandSeparator(data.item.montant_paye) }} </template
          ><template #cell(montant_vente)="data">
            {{ $thousandSeparator(data.item.montant_vente) }} </template
          ><template #cell(reste_a_paye)="data">
            {{ $thousandSeparator(data.item.reste_a_paye) }}
          </template>
        </b-table>
        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span>
	            <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
            </span>
            <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            class="pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
        <!-- modal facture start -->
        <b-modal
            id="facture-modal"
            v-model="factureModal"
            size="lg"
            title="Nouvelle Facture"
            centered
            @hidden="factureResetModal"
        >
          <template #modal-footer>
            <b-button
                variant="outline-secondary"
                class="mr-1 float-right"
                @click="factureModal = false"
            >
              Annuler
            </b-button>
            <b-button
                v-if="etatButtonfacture"
                variant="primary"
                class="float-right"
                @click="sellHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </template>

          <!-- form start -->
          <b-form
              class="mt-1"
              ref="sellForm"
              @submit.stop.prevent="sellHandleSubmit"
          >
            <b-row>
              <b-col cols="12">
                <div>
                  <form-wizard
                      ref="form_wizard"
                      color="#27295c"
                      :title="null"
                      :subtitle="null"
                      shape="square"
                      :validateOnBack="true"
                      back-button-text="Précédent"
                      nextButtonText="Suivant"
                      class="mb-3"
                      @on-complete="formSubmitted"
                  >
                    <!-- voiture tab -->
                    <tab-content title="Véhicule" :before-change="validationFormVehicule">
                      <validation-observer ref="CarRules" tag="form">
                        <b-row>
                          <b-col md="4">
                            <b-form-group label="Numéro chassis" label-for="num_chassi">
                              <validation-provider
                                  #default="{ errors }"
                                  name="num_chassi"
                              >
                                <!-- <b-form-select
                                  id="clientName"
                                  v-model="sellForm.client"
                                  :options="clientsList"
                                  value-field="id"
                                  text-field="name"
                                  @input="client_nameSelectSellReset"
                                  :state="errors.length > 0 ? false : null"
                                /> -->
                                <Select2
                                    id="num_chassi"
                                    :options="[].slice.call(vehicules).map(_c => { return {value: _c.id, text: _c.numero_chassis} })"
                                    dropDownParent="#facture-modal___BV_modal_content_"
                                    lang="fr"
                                    :change="car_nameSelectSellReset"
                                />
                                <!-- <b-form-input
                                  list="clientsList"
                                  id="clientName"
                                  v-model="sellForm.client"
                                  @change="client_nameSelectSellReset()"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : client_nameSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group
                                label="Marque"
                                label-for="marque"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="marque"
                                  rules=""
                              >
                                <b-form-input
                                    id="marque"
                                    v-model="sellForm.marque"
                                    @keyup="checkCarDataMarque()"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Marque"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>

 <!-- client tab -->
                    <tab-content title="Client" :before-change="validationFormClient">
                      <validation-observer ref="clientRules" tag="form">
                        <b-row>
                          <b-col md="4">
                            <b-form-group label="Client" label-for="clientName">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientName"
                              >
                                <!-- <b-form-select
                                  id="clientName"
                                  v-model="sellForm.client"
                                  :options="clientsList"
                                  value-field="id"
                                  text-field="name"
                                  @input="client_nameSelectSellReset"
                                  :state="errors.length > 0 ? false : null"
                                /> -->
                                <Select2
                                    id="clientName"
                                    :options="[].slice.call(clients).map(_c => { return {value: _c.id, text: _c.name} })"
                                    dropDownParent="#facture-modal___BV_modal_content_"
                                    lang="fr"
                                    :change="client_nameSelectSellReset"
                                />
                                <!-- <b-form-input
                                  list="clientsList"
                                  id="clientName"
                                  v-model="sellForm.client"
                                  @change="client_nameSelectSellReset()"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : client_nameSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col class="px-0 mt-2 col-2">
                            <b-button
                                variant="outline-dark"
                                size="md"
                                class=""
                                @click="modalAddClient = true"
                            >
                              Nouveau
                            </b-button>
                          </b-col>

                          <b-col md="6">
                            <b-form-group
                                label="Téléphone"
                                label-for="clientPhone"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientPhone"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientPhone"
                                    v-model="sellForm.client_phone"
                                    @keyup="checkClientDataPhone()"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Téléphone"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Ifu" label-for="clientIfu">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientIfu"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientIfu"
                                    v-model="sellForm.client_ifu"
                                    type="text"
                                    :disabled="true"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Ifu"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Reccm" label-for="clientReccm">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientReccm"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientReccm"
                                    v-model="sellForm.client_reccm"
                                    type="text"
                                    disabled="true"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Reccm"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>


                    <!-- deposant tab -->
                    <tab-content
                        title="Déposant"
                        :before-change="validationFormDeposant"
                    >
                      <validation-observer ref="deposantRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                                label="Nom et Prénom"
                                label-for="deposantName"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="deposantName"
                                  rules=""
                              >
                                <b-form-input
                                    id="deposantName"
                                    type="text"
                                    v-model="sellForm.deposant_name"
                                    placeholder="Nom et Prénom"
                                    :disabled="activeCheckBox == 1 ? false : true"
                                    :state="errors.length > 0 ? false : null"
                                    @input="deposant_nameInputSellReset"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : deposant_nameSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                                label="Téléphone"
                                label-for="deposantPhone"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="deposantPhone"
                                  rules=""
                              >
                                <b-form-input
                                    id="deposantPhone"
                                    type="number"
                                    v-model="sellForm.deposant_phone"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Téléphone"
                                    :disabled="activeCheckBox == 1 ? false : true"
                                    @input="deposant_phoneInputSellReset"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : deposant_phoneSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <div class="mb-1">
                          <span>Existe t'il un déposant ? </span>
                          <b-form-checkbox
                              class="ml-2"
                              style="padding-top: 3px"
                              switch
                              inline
                              value="1"
                              unchecked-value="0"
                              name="check-button"
                              v-model="activeCheckBox"
                              @change="inputDeposantReset"
                          >
                          </b-form-checkbox>
                        </div>
                      </validation-observer>
                    </tab-content>

                    <!-- signataire tab  -->
                    <tab-content
                        title="Signataire"
                        :before-change="validationFormSignataire"
                    >
                      <validation-observer ref="signataireRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group class="col pr-0">
                              <validation-provider
                                  #default="{ errors }"
                                  name="signataireName"
                                  rules="required"
                              >
                                <label for="signataireName">Nom signataire</label>
                                <b-form-select
                                    id="signataire"
                                    v-model="sellForm.signataire"
                                    :options="signataires"
                                    value-field="name"
                                    text-field="name"
                                    @input="signataire_nameSelectSellReset"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                            <!--                    <b-form-group
                                                  label="Nom signataire"
                                                  label-for="signataireName"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="signataireName"
                                                    rules="required"
                                                  >
                                                    <b-form-select
                                                        ref="signataires"
                                                        v-model="venteForm.signataire"
                                                        :options="signataires"
                                                        value-field="name"
                                                        text-field="name"
                                                        @change="checkSignatairesData()"
                                                        :state="errors.length > 0 ? false : null"
                                                    >
                                                    </b-form-select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                  </validation-provider>
                                                </b-form-group>-->
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Téléphone" label-for="signatairePhone">
                              <validation-provider
                                  #default="{ errors }"
                                  name="signatairePhone"
                                  rules=""
                              >
                                <b-form-input
                                    id="signatairePhone"
                                    type="number"
                                    value-field="name"
                                    text-field="name"
                                    @keyup="checkSignatairesDataPhone()"
                                    v-model="sellForm.signataire_phone"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Téléphone"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>


                    <!-- details du paiement -->
                    <tab-content
                        title="Détails paiement"
                        :before-change="validationFormDetails"
                    >
                      <validation-observer ref="detailsRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                                label="Type de vente"
                                label-for="typeVente"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="typeVente"
                                  rules=""
                              >
                                <b-form-select
                                    id="typeVente"
                                    v-model="sellForm.type_vente"
                                    :options="typeVenteList"
                                    @input="type_venteSelectSellReset"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : type_venteSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group
                                label="Montant payé"
                                label-for="montant_paye"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="montant_paye"
                                  rules="required"
                              >
                                <cleave
                                    id="montant_paye"
                                    v-model="sellForm.montant_paye"
                                    class="form-control"
                                    :raw="false"
                                    placeholder="0"
                                    :options="numberFormat"
                                    @input="montant_payeInputSellReset"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : montant_payeSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>
                  </form-wizard>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal sell end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center text-secondary mt-2 mb-2">
        <empty-list></empty-list>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import vSelect from "vue-select";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import Cleave from "vue-cleave-component";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import downloadExcel from "json2excel-js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import Select2 from "@/components/Select2.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BIconCircleFill,
  BIconFileEarmarkArrowDownFill,
  BRow,
  BCol,
  BContainer,
  BPagination,
  BFormSelectOption,
  BIconPlus,
  BCard,
  BTable,
  BCardText,
  BFormDatalist,
  BTooltip,
  BIconArrowClockwise,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCartPlus,
  VBTooltip,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import { json2excel, excel2json } from "js2excel";
import factures from "@/views/archives/Factures";

export default {
  components: {
    BBadge,
    BContainer,
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BFormDatalist,
    EmptyList,
    BIconPlus,
    BIconArrowClockwise,
    BRow,
    BCol,
    BCard,
    Cleave,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BIconCartPlus,
    BFormDatepicker,
    DatePicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
    Select2
  },
  data() {
    return {
      filter: "",
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      required,
      pageLength: 10,
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Decroissant", value: "decroissant" },
      ],
      invoice_types: [
        { text: "Facture simple", value: "Facture simple" },
        { text: "Facture normalisée", value: "Facture normalisée" },
      ],
      selectedTrie: null,
      dir: false,
      selected: [true],
      selectedModule: "",
      selectedModules: [],
      addFunctionModal: false,
      isOperating: false,
      factures: [],
      ExcelFactures: null,
      startDateFilterValue: null,
      endDateFilterValue: null,
      factureModal: false,
      newClient: {},
      modalAddClient: false,
      etatButtonAddClient: true,
      errorsNameModal: null,
      errorsPhoneModal: null,
      errorsIfuModal: null,
      errorsReccmModal: null,
      client: null,
      client_reccm: null,
      client_ifu: null,
      client_phone: null,
      marque: null,
      signataire: null,
      signataires: [],
      clients: [],
      vehicules: [],
      signataire_phone: null,
      etatButtonEditVehicule: true,
      etatButtonPrintVehicule: true,
      etatButtonProforma: true,
      etatButtonfacture: true,
      numero_chassisPrintModalErrors: null,
      montantPrintModalErrors: null,
      dialogProforma: false,
      numero_chassisEditModalErrors: null,
      marqueEditModalErrors: null,
      couleurEditModalErrors: null,
      anneeEditModalErrors: null,
      client_nameSellModalErrors: null,
      client_phoneSellModalErrors: null,
      client_ifuSellModalErrors: null,
      client_reccmSellModalErrors: null,
      deposant_nameSellModalErrors: null,
      deposant_phoneSellModalErrors: null,
      signataire_nameSellModalErrors: null,
      signataire_phoneSellModalErrors: null,
      type_venteSellModalErrors: null,
      montant_payeSellModalErrors: null,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      sellForm: {
        client: '',
        client_phone: '',
        client_ifu: '',
        client_reccm: '',
        marque: '',
        vehicule_id:0,
        invoice_type: "Facture normalisée",
      },
      activeCheckBox: 0,
      typeVenteList: [
        { text: "Transit", value: "Transit" },
        { text: "Local", value: "Local" },
      ],
      societeObjet: {},
      allRows: 0,
      facturesSearch: [],
      perPage: 10,
      currentPage: 1,
      pageOptions: [],
      fields: [
        {
          label: "Date",
          align: "left",
          sortable: false,
          key: "created_at",
        },
        { label: "Numéro", key: "facture_no", sortable: true },
        { label: "Client", key: "client.name", sortable: true },
        { label: "Véhicule", key: "vehicule", sortable: true },
        {
          label: "Association / Importateur",
          key: "association_importateur",
          sortable: true,
        },
        { label: "P. Vente", key: "montant_vente" },
        // { label: "Avance", key: "avance_percue" },
        { label: "Montant payé", key: "montant_paye" },
        { label: "RAP", key: "reste_a_paye" },
        // {
        //   label: "Statut",
        //   key: "statut",
        //   filter: this.statusFilter,
        //   sortable: true,
        // },
      ],
      statusList: [
        { text: "Tout", value: null },
        { text: "Archivé", value: 1 },
        { text: "Non archivé", value: 0 },
      ],
      valueSearch:"",
      type_factures: [
        {
          label: "ASSOCIATIONS",
          options: [],
        },
        {
          label: "IMPORTATEURS",
          options: [],
        },
      ],
      selectedStatut: null,
      selectedType: null,
      selectedStatusInvoice: null,
      statusFilterValue: null,
      groupItem: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
  },
  created() {
    this.getFactures();
    this.getAssociations();
    this.getImportateurs();
    this.getSociete();
    this.getVehicules();
    this.getMarques();
    this.getCouleurs();
    this.getClients();
    this.getSignataires();
    this.selectedTrie = "decroissant"
  },
  methods: {
    getFactures() {
      this.$http.get(`/factures`).then((result) => {
        if (result.status) {
          this.hiddenLoader = true;
        }
        result.data.data.forEach((element) => {
          // console.log(element)
          if(element.original_invoice && element.invoice_type == "Facture normalisée" && element.archive){
            element._rowVariant = 'danger'
          }
          if (element.archive) element.statut = 1;
          else element.statut = 0;

          if (!element.facture_no) element.facture_no = "-----";
          if (!element.association_importateur)
            element.association_importateur = "-----";
        });

        this.factures = result.data.data;

        this.factures.sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          });

        if (this.factures.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
        this.allRows = this.factures.length

      });
    },
    async getClients() {
      await this.$http.get("/clients").then((response) => {
        this.clients = response.data.data.reverse();
        /*    let newcli=this.clients[0];
            this.client=newcli.name
            this.client_phone=newcli.phone;*/
        this.clients.find(({ text }) => text === this.client)
        this.clients.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    async getSignataires() {
      await this.$http.get("/signataires").then((response) => {
        this.signataires = response.data.data.reverse();
        if (this.signataires != null && this.signataires.length===1){
          let newsig=this.signataires[0];
          this.signataire=newsig.name
          this.signataire_phone=newsig.phone
        }
        this.signataires.find(({ text }) => text === this.signataires)
        this.signataires.forEach((element) => {
          element.text = element.name;
        });

      });
    },
    getVehicules() {
      this.$http
          .get("/vehicules")
          .then((res) => {
            if (res.status) {
              this.hiddenLoader = true;
            }

            let vehicules = res.data.data;
            this.allVehicules=vehicules;
            //console.log(this.allVehicules)
            vehicules.forEach((element) => {
              element.association = element.manifeste.association.name;
            });

            this.vehicules = vehicules.reverse();
            this.vehiculesList = vehicules.reverse();

            this.pricesReview()
            this.vehicules = this.allVehicules.filter((value)=>["en stock","en dépôt"].includes(value.statut))
            this.selectedStatut=this.statut_options[3].value;

            this.vehicules.forEach((element) => {

              if(element.date_arrivee == null) {
                element.date_arrivee = element.manifeste.date_arrivee

                var parts = element.date_arrivee.split("-");
                element.date_arrivee = parts[2]+'-'+parts[1]+'-'+parts[0];
              }
            });


            if (this.vehiculesList.length) {
              this.hidden = false;
              this.hiddenEmpty = true;
            } else {
              this.hidden = true;
              this.hiddenEmpty = false;
            }


            this.allRows = this.vehicules.length
          })
          .catch((e) => {
            console.log("erreur de récupération:" + e);
          });
    },
    getAssociations() {
      this.$http
        .get("/associations")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.name,
              text: element.name,
            };

            this.type_factures[0].options.push(obj);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    client_nameSelectSellReset(e) {
      const _id = e.target.value;
      const selected = this.clients.find(_c => _c.id == _id);
      if (selected) {
        console.log(selected)
        this.sellForm.client = selected
        this.sellForm.client_reccm = selected.reccm;
        this.sellForm.client_ifu = selected.ifu;
        this.sellForm.client_phone = selected.phone;
      }
    },
    checkClientDataPhone() {
      let chosen;
      if (this.client_phone) {
        chosen = this.clients.find(({ phone }) => phone === this.client_phone);
      }
      //   console.log("checkClientDataPhone checkClientDataPhone")
      if (chosen) {
        let data = {
          value: chosen.id,
          text: chosen.name
        };
        let newOption = new Option(data.text, data.value, false, true);
        $('#clientNom').append(newOption).trigger('change');
        this.client = chosen;
        this.client_reccm = chosen.reccm;
        this.client_ifu = chosen.ifu;
      } else {
      }
    },
    checkSignatairesDataPhone() {
      let chosen;

      if (this.signataire_phone) {
        chosen = this.signataires.find(
            ({ phone }) => phone === this.signataire_phone
        );
      }
      if (chosen) {
        this.signataire = chosen.name;
      } else {
      }
    },
    checkCarDataMarque() {
      let chosen;
      if (this.sellForm.marque) {
        chosen = this.vehicules.find(({ marque }) => marque === this.marque);
      }
      //   console.log("checkClientDataPhone checkClientDataPhone")
      if (chosen) {
        let data = {
          value: chosen.id,
          text: chosen.numero_chassis
        };
        let newOption = new Option(data.text, data.value, false, true);
      } else {
      }
    },
    car_nameSelectSellReset(e) {
      const _id = e.target.value;
      const selected = this.vehicules.find(_c => _c.id == _id);
      if (selected) {
        this.sellForm.vehicule = selected
        this.sellForm.vehicule_id = selected.id;
        this.sellForm.marque = selected.marque;

      }
    },
    validationFormClient() {
      return new Promise((resolve, reject) => {
        this.$refs.clientRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length
      this.facturesSearch=filteredItems;
      this.currentPage = 1
      },

      changeTrie() {
      if(this.selectedTrie == "croissant") {
        this.factures = this.factures.reverse();
      }
      if(this.selectedTrie == "decroissant") {
      this.factures = this.factures.reverse();
      }
      },
    getMarques() {
      this.$http
          .get("/marques")
          .then((res) => {
            this.marquesList = res.data.data.reverse();
          })
          .catch((e) => {
            console.log("erreur marqueList:" + e.response.data.message);
          });
    },
    getCouleurs() {
      this.$http
          .get("/couleurs")
          .then((res) => {
            this.couleursList = res.data.data.reverse();
          })
          .catch((e) => {
            console.log("erreur couleurList:" + e.response.data.message);
          });
    },

    getImportateurs() {
      this.$http
        .get("/importateurs")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.name,
              text: element.name,
            };

            this.type_factures[1].options.push(obj);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sellHandleSubmit() {
      // code
    },
    async getSociete() {
      await this.$http.get("/societes").then((response) => {
        this.societeObjet = response.data.data[0];
        this.Isimportateur= this.societeObjet.importateur

      });
    },

    startDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.startDateFilterValue = val;
    },

    endDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.endDateFilterValue = val;
    },

    selectedTypeFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.selectedType = val;
    },
    selectedInvoiceFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.selectedStatusInvoice = val;
    },

    montant_payeInputSellReset() {
      // code
    },
    statusFilter(value) {
      if (!this.statusFilterValue) {
        return true;
      }
      return value === this.statusFilterValue;
    },
    itemRowBackground: function (item) {
      return item.archive ? "archive" : "normal";
    },

    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        if (value >= start) {
          return true;
        } else {
          return false;
        }
        //return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },

    filterValue(val) {
      this.filter = " ";
    },

    filterFunc(row, DateFilter) {
      var start = null;
      var end = null;
      var value = new Date(this.toDate(row.created_at));

      if (this.startDateFilterValue) {
        var parts = this.startDateFilterValue.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (this.endDateFilterValue) {
        var parts = this.endDateFilterValue.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (
        row.client.name.toLowerCase().indexOf(this.valueSearch.toLowerCase()) ==
          -1 &&
        row.montant_vente
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.facture_no.toLowerCase().indexOf(this.valueSearch.toLowerCase()) ==
          -1 &&
        row.vehicule.marque
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.vehicule.numero_chassis
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1
      ) {
        return false;
      }
      if (this.selectedStatusInvoice != null && this.selectedStatusInvoice != row.invoice_type) {
        return false;
      }
      else if (this.selectedType != null && this.selectedType != row.association_importateur) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }

    },
    deposant_nameInputSellReset() {
      // code
    },
    type_venteSelectSellReset() {
      // code
    },
    deposant_phoneInputSellReset() {
      // code
    },
    signataire_nameSelectSellReset(lastSelectItem) {
      if (this.sellForm.signataire) {
        let chosen = this.signataires.find(
            ({ text }) => text === lastSelectItem
        );
        if (chosen) this.sellForm.signataire_phone = chosen.phone;
      }
    },

    resetInputIfuModal() {
      if (this.errorsIfuModal) {
        this.errorsIfuModal = null;
      }
    },

    resetInputReccmModal() {
      if (this.errorsReccmModal) {
        this.errorsReccmModal = null;
      }
    },

    validationFormVehicule() {
      return new Promise((resolve, reject) => {
        this.$refs.CarRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDeposant() {
      return new Promise((resolve, reject) => {
        this.$refs.deposantRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormSignataire() {
      return new Promise((resolve, reject) => {
        this.$refs.signataireRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.detailsRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },


    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
      // return this.reformatDate(date)
    },
    onRowClicked(item, index, event) {
      let routeData = this.$router.resolve({
        name: "facture-show",
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    newfacture() {
      this.sellForm.societe = this.societeObjet.id;
      this.sellForm.type_vente = "Transit";
      this.factureModal = true;
    },
    sellHandleOk(bvModalEvt) {
      this.sellForm.montant_paye = this.$thousandFormater(
          this.sellForm.montant_paye
      );

      let today = new Date();
      let vehicule = this.sellForm;

      var date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();

      let type = "";

      switch (vehicule.type_vente) {
        case "Transit":
          type = "EV";
          break;
        case "Local":
          type = "FV";
          break;
      }
      const customer = this.clients.find(
          (_client) => _client.id === vehicule.client.id
      );
      console.log(customer)
      const operator = this.signataires.find(
          (_sig) => _sig.id === vehicule.signataire
      );
      console.log(operator)

      let newifu
      if(vehicule.client_ifu===null){
        newifu="0000000000000"
      }else if(vehicule.client_ifu.length< 13){
        newifu="0000000000000"
      }
      else {
        newifu=vehicule.client_ifu
      }

      let data = {
        type: type,
        orignal: "000",
        date: date,
        reference: "-------",
        discount: 0,
        company: {
          name: this.societeObjet.name,
          address: this.societeObjet.address,
          contact: this.societeObjet.telephone,
          ifu: this.societeObjet.ifu,
        },
        operator: {
          id: this.user.id,
          name: operator?operator.name:'',
        },
        customer: {
          name: customer?customer.name:'',
          ifu: newifu,
          amount: this.sellForm.montant_paye,
          change: 0,
        },
        vat: {
          type: "A",
          value: 0,
        },
        tax: 0,
        aib: "",
        products: [
          {
            description: vehicule.vehicule.marque + "-" + vehicule.vehicule.numero_chassis,
            price: this.parsing(this.sellForm.montant_paye),
            quantity: 1,
          },
        ],
      };

      var myHeaders = new Headers();
      myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("content", JSON.stringify(data));

      var myInit = {
        method: "POST",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      };

      this.etatButtonfacture = false;
      fetch("http://127.0.0.1:3440", myInit)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            // console.log(res);
            if (!res.success) {
              this.etatButtonfacture = true;
              let message = 'Impossible de joindre le serveur MECEF'
              if (res.result == "No device connected")
                message = "Aucun appareil connecté";
              else if (res.result == "Device not compatible")
                message = "Appareil MECEF non compatible";
              else message = "Impossible de traiter la demande";
              this.showToast(message, "danger");
            } else {
              this.sellForm.mcf_request_content = res.result;
              this.standardizedInvoiceGenerated = res;
              this.sellCar();
            }
          })
          .catch((error) => {
            this.etatButtonfacture = true;
            // console.log(error);
            this.showToast("Impossible de joindre le serveur MCF", "danger");
          });
    },
    async sellCar() {
      this.isOperating = true;
      this.sellForm.montant_paye = this.$thousandFormater(
          this.sellForm.montant_paye
      );
      console.log('My sell',this.sellForm)
      this.$http
          .post("/carte-importateur/newfacture", this.sellForm)
          .then((response) => {
            this.isOperating = false;
            if (response.data.success) {
              this.etatButtonfacture = true;
              this.showToast("Facture effectuée avec succès.", "success");
              let id = response.data.data.facture[response.data.data.facture.length - 1].id;
              this.factureModal = false;
              let routeData = this.$router.resolve({
                name: "facture-show",
                params: { id: id },
              });
              window.open(routeData.href, "_blank");
              this.getFactures();
              this.getVehicules();
            } else {
              this.etatButtonfacture = true;
              this.showToast(response.data.message, "danger");
            }
          })
          .catch((err) => {
            this.etatButtonfacture = true;
            // this.showToast(err.response.data.message, "danger");
          });
    },
    inputDeposantReset() {
      if (this.activeCheckBox != 1) {
        this.sellForm.deposant_name = "";
        this.sellForm.deposant_phone = "";
      }
    },

    factureResetModal() {
      this.sellForm.client = "";
      this.sellForm.client_phone = "";
      this.sellForm.client_ifu = "";
      this.sellForm.client_reccm = "";
      this.sellForm.deposant_name = "";
      this.sellForm.deposant_phone = "";
      this.sellForm.signataire = "";
      this.sellForm.signataire_phone = "";
      this.sellForm.type_vente = "";
      this.sellForm.montant_paye = "";
    },
    parsing(amount) {
      return parseInt(amount);
    },

    formSubmitted() {
      console.log(this.sellForm);
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelFactures.forEach((element) => {
        obj = {
          DATE: element.created_at,
          NUMERO: element.facture_no,
          CLIENT: element.client.name,
          VEHICULE:
            element.vehicule.marque +
            " - " +
            element.vehicule.numero_chassis +
            " - " +
            "Année " +
            element.vehicule.annee,
          "PRIX VENTE": element.montant_vente,
          "AVANCE PERÇUE": element.avance_percue,
          "MONTANT PAYÉ": element.montant_paye,
          "RESTE À PAYER": element.reste_a_paye,
          STATUT: element.statut ? "archivé" : "non archivé",
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Factures",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};
      if (this.facturesSearch.length===0){
        this.facturesSearch=this.factures
      }
      this.facturesSearch.forEach((element) => {
        obj = {
          DATE: element.created_at,
          NUMERO: element.facture_no,
          CLIENT: element.client.name,
          VEHICULE:
            element.vehicule.marque +
            " - " +
            element.vehicule.numero_chassis +
            " - " +
            "Année " +
            element.vehicule.annee,
          "PRIX VENTE": element.montant_vente,
          "AVANCE PERÇUE": element.avance_percue,
          "MONTANT PAYÉ": element.montant_paye,
          "RESTE À PAYER": element.reste_a_paye,
          STATUT: element.statut ? "archivé" : "non archivé",
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Factures",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.archive {
  border-left: 15px solid #ffb74d !important;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}
.date-picker {
  width: 10rem !important;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  //   color: #27295c !important;
  //   border: 1px solid #27295c !important;
  //   background-color: white !important;
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
        margin-bottom: 5px;
      } */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  // #solde-input {
  // 	margin-left: 20px !important;
  // }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}

@media screen and (max-width: 700px) {
  .container-filter {
    display: block !important;
  }

  .container-search, .container-importateur,
  .container-date-start, .container-date-end,
  .container-solde {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 20px;
  }

  .container-solde {
    margin-left: 0px !important;
  }

  .container-statutfilter {
    max-width: 50% !important;
    flex: initial !important;
  }
}
</style>


